import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "120px 24px 0 24px",
    background: "linear-gradient(45deg, black, #1E2021)",
    [theme.breakpoints.down("sm")]: {
      minHeight: "300px",
      paddingTop: "96px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "50px",
    textAlign: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
  logos: {
    maxWidth: "1100px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "50px",
    paddingBottom: "96px",
  },
  logo: {
    display: "flex",
    maxWidth: "175px",
    margin: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
    },
  },
  title: {
    text: "uppercase",
    fontSize: "54px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px",
    },
  },
}))

export const PressHero = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        pressPage {
          PressLongTitle
          PressTitle
          PressLogos {
            images {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 170) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {data.strapi.pressPage.PressTitle}
        </Typography>
        <Typography
          variant="h3"
          style={{
            marginTop: "20px",
            color: "#F26522",
          }}
        >
          {data.strapi.pressPage.PressLongTitle}
        </Typography>
      </Box>
      <Box className={classes.logos}>
        {data.strapi.pressPage.PressLogos.map(({ images }) => (
          <Img
            key={images}
            className={classes.logo}
            fixed={images.imageFile.childImageSharp.fixed}
          />
        ))}
      </Box>
    </Box>
  )
}
