import { Box, makeStyles, Typography, Link } from "@material-ui/core"
import { format } from "date-fns"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
    transition: "all 300ms ease-in-out",
  },
  layerBottom: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
  },
  mainLayerBottom: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
    height: "100%",
  },
  image: {
    height: "100%",
    "&:hover": {
      filter: "brightness(110%)",
    },
    "& img": {
      transition: "all 800ms ease !important",
    },
    "&:hover img": {
      transform: "scale(1.1)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40vw",
    },
  },
  layerTop: {
    position: "relative",
    gridRow: 1,
    [theme.breakpoints.down("sm")]: {
      gridRow: 2,
    },
    gridColumn: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  title: {
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    paddingBottom: "30px",
    backgroundColor: "#111",
  },
  mainTitle: {
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    paddingBottom: "30px",
    backgroundColor: "#111",
  },
  logo: {
    maxWidth: "155px",
    marginBottom: "15px",
  },
}))

export const PressPreview = ({ press }) => {
  const classes = useStyles()

  return (
    <Link href={press.PressUrl} style={{ textDecoration: "none" }}>
      <Box className={classes.container}>
        <Box className={classes.layerTop}>
          <Box className={classes.layerBottom}>
            <Img
              fluid={press.Thumbnail.imageFile.childImageSharp.fluid}
              className={classes.image}
            />
          </Box>
          <Box className={classes.title}>
            <Box className={classes.logo}>
              {!!press &&
                !!press.AuthorLogo &&
                !!press.AuthorLogo.imageFile &&
                !!press.AuthorLogo.imageFile.childImageSharp &&
                !!press.AuthorLogo.imageFile.childImageSharp.fixed && (
                  <Img
                    fixed={press.AuthorLogo.imageFile.childImageSharp.fixed}
                  />
                )}
            </Box>
            <Typography
              variant="h3"
              style={{
                marginBottom: "10px",
                maxWidth: "725px",
              }}
            >
              {press.Title}
            </Typography>
            <Typography variant="caption" style={{ fontWeight: "700" }}>
              {format(new Date(press.PublisheAt), "dd/MM/yyyy")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

export const PressPreviewMain = ({ press }) => {
  const classes = useStyles()

  return (
    <Link href={press.PressUrl} style={{ textDecoration: "none" }}>
      <Box className={classes.container}>
        <Box className={classes.layerTop}>
          <Box className={classes.mainLayerBottom}>
            <Img
              fluid={press.Thumbnail.imageFile.childImageSharp.fluid}
              className={classes.image}
            />
          </Box>
          <Box className={classes.mainTitle}>
            <Box className={classes.logo}>
              {!!press &&
                !!press.AuthorLogo &&
                !!press.AuthorLogo.imageFile &&
                !!press.AuthorLogo.imageFile.childImageSharp &&
                !!press.AuthorLogo.imageFile.childImageSharp.fixed && (
                  <Img
                    fixed={press.AuthorLogo.imageFile.childImageSharp.fixed}
                  />
                )}
            </Box>
            <Typography
              variant="h1"
              style={{
                marginBottom: "10px",
                maxWidth: "725px",
              }}
            >
              {press.Title}
            </Typography>
            <Typography
              style={{
                margin: "20px 0",
                maxWidth: "700px",
              }}
              variant="body1"
            >
              {press.Lead}
            </Typography>
            <Typography variant="caption">
              {format(new Date(press.PublisheAt), "dd/MM/yyyy")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}
