import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout-home"
import { PressHero } from "../components/press/press-hero"
import { FeaturedPress } from "../components/press/featured-press"
import { PressArticleActual } from "../components/press/press-articles-actual"
import { Box } from "@material-ui/core"
//import { PressArticle2019 } from "../components/press/press-articles-2019"
//import { PressArticle2018 } from "../components/press/press-articles-2018"

const PressPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        pressArticles {
          id
          Lead
          PublisheAt
          Title
          PressUrl
          Thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          AuthorLogo {
            url
            imageFile {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PressHero />
      <Box maxWidth={"1400px"} margin="auto" marginTop="48px">
        <FeaturedPress press={data.strapi.pressArticles} />
        <PressArticleActual pressArticles={data.strapi.pressArticles} />
      </Box>
    </Layout>
  )
}

export default PressPage
