import React from "react"
import { Box, Typography, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { format } from "date-fns"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  container: {
    margin: "2px",
    display: "grid",
    height: "100%",
    "&:hover": {
      filter: "brightness(130%)",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
    transition: "all 300ms ease-in-out",
  },
  layerBottom: {
    gridRow: 1,
    gridColumn: 1,
    display: "block",
    height: "100%",
    zIndex: "-1",
  },

  image: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "40vw",
    },
  },
  layerTop: {
    position: "relative",
    gridRow: 1,
    [theme.breakpoints.down("sm")]: {
      gridRow: 2,
    },
    gridColumn: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  title: {
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    minHeight: "260px",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    paddingBottom: "30px",
    backgroundColor: "#111",
    marginTop: "-10px",
  },

  logo: {
    maxWidth: "175px",
    marginBottom: "15px",
  },
}))

export const PressArticleCard = ({ articles }) => {
  const classes = useStyles()

  const { Title, Thumbnail, PressUrl, AuthorLogo, PublisheAt } = articles

  return (
    <Link href={PressUrl} style={{ textDecoration: "none" }}>
      <Box className={classes.container}>
        <Box className={classes.layerTop}>
          <Box className={classes.layerBottom}>
            <Img
              alt={Title}
              width="100%"
              height="100%"
              style={{ objectFit: "cover", height: "100%" }}
              fluid={Thumbnail.imageFile.childImageSharp.fluid}
            />
          </Box>
          <Box className={classes.title}>
            <Box>
              {!!AuthorLogo &&
                !!AuthorLogo.imageFile &&
                !!AuthorLogo.imageFile.childImageSharp &&
                !!AuthorLogo.imageFile.childImageSharp.fixed && (
                  <Img
                    fixed={AuthorLogo.imageFile.childImageSharp.fixed}
                    alt={Title}
                    width="100"
                    style={{ opacity: "70%", marginBottom: "15px" }}
                  />
                )}
            </Box>
            <Typography
              variant="h3"
              style={{
                marginBottom: "10px",
                maxWidth: "725px",
              }}
            >
              {Title}
            </Typography>
            <Typography variant="caption" style={{ fontWeight: "700" }}>
              {format(new Date(PublisheAt), "dd/MM/yyyy")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}
