import { Box, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { Button } from "@material-ui/core"
import _ from "lodash"
import { useShowMoreByYear } from "../utils/useShowMoreByYear"
import { PressArticleCard } from "./press-article-card"

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "64px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "16px",
    },
  },
  main: {
    gridRow: "span 2",
  },
  articleContainer: {
    width: "100%",
    display: "grid",
    gap: "24px",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  },
}))

export const PressArticleActual = ({ pressArticles }) => {
  const classes = useStyles()
  const articlesByYear = _.groupBy(pressArticles, article => {
    const date = new Date(Date.parse(article.PublisheAt))
    article.PublisheAt = date
    return date.getFullYear()
  })
  const { slicedItemsByYear, hasMore, showMore } = useShowMoreByYear({
    itemsByYear: articlesByYear,
  })
  return (
    <Box>
      {Object.keys(slicedItemsByYear)
        .sort((a, b) => b - a)
        .map(year => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            key={year}
          >
            <Box className={classes.titleWrapper}>
              <Typography variant="h1">{year}</Typography>
              <Typography
                variant="h3"
                color="primary"
                style={{ fontWeight: "700" }}
              >
                Articles
              </Typography>
            </Box>
            <Box className={classes.articleContainer}>
              {articlesByYear[year]
                .sort((a, b) => b.PublisheAt - a.PublisheAt)
                .map(article => {
                  return <PressArticleCard articles={article} key={article} />
                })}
            </Box>
            <Box className={classes.mainNewsContainer}></Box>
          </Box>
        ))}
      <Box width="100%" display="flex" justifyContent="center" p={6}>
        {hasMore && (
          <Button variant="contained" onClick={showMore}>
            Show more
          </Button>
        )}
      </Box>
    </Box>
  )
}
