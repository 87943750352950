import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import { PressPreview, PressPreviewMain } from "./press-preview"

const useStyles = makeStyles(theme => ({
  mainNewsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1.2fr 1fr",
    minHeight: "700px",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  },

  main: {
    gridRow: "span 2",
  },
  sideNewsContainter: {},
}))

export const FeaturedPress = ({ press }) => {
  const classes = useStyles()
  const orderedPress = press.sort(
    (a, b) => Date.parse(b.PublisheAt) - Date.parse(a.PublisheAt)
  )

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box className={classes.mainNewsContainer}>
        {orderedPress[0] && (
          <Box className={classes.main}>
            <PressPreviewMain press={orderedPress[0]} />
          </Box>
        )}

        {orderedPress[1] && (
          <Box className={classes.sideNewsContainter}>
            <PressPreview
              className={classes.sideNewsContainter}
              press={orderedPress[1]}
            />
          </Box>
        )}
        {orderedPress[2] && (
          <Box className={classes.sideNewsContainter}>
            <PressPreview press={orderedPress[2]} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
